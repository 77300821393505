import { Grid } from '@mui/material';
import { ProceedSaveLater, SubHeading } from './components';
import { Formik, validateYupSchema, yupToFormErrors } from 'formik';
import MFSelectField from '../../lib/formik/SelectField';
import { Applicant, Broker, BrokerList } from '../../redux-store/types/api-types';
import {
  DLCLMasters,
  grossAnnualMasters,
  occupationDetailsMasters,
  PEPsMasters,
  SAVE_LATER_VALIDATION_CHECK,
  USER_ROLES,
} from '../../utils/constant';
import {
  // eslint-disable-next-line
  allowOnlyNumbers,
  applicationComparison,
  clearclIdField,
  cleardpIdField,
  getApplicantName,
  getStep,
  getclIdField,
  getdpIdField,
  isCDSL,
  isFirstApplicant,
  nameOfBrokerExists,
  otherNameOfBroker,
  saveForLater,
  sortListKeepOthersLast,
  statusRoleBased,
} from '../../utils/utilityFunctions';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../redux-store/reducers';
import { useEffect, useState } from 'react';
import { updateApplication } from '../../redux-store/actions/application';
import { useHistory } from 'react-router';
import { KYCDetailsSchema } from '../../utils/schema';
import { MFTextField } from '../../lib/formik';
import { useSnackbar } from 'notistack';
import { SearchableSelect } from '../../lib/formik/searchSelectField';
import { useFeatureToggles, useMdmsBrokerListData } from '../../utils/useDataMdms';
import FieldValidationNote from './FieldValidationNote';

type Values = {
  applicants: Partial<Applicant & { otherNameOfBroker: string; applicant_type: string }>[];
  saveType: string;
};

const initialValues: Values = {
  applicants: [
    {
      occupationDetails: '',
      grossAnnualIncome: '',
      politicallyExposedPersonStatus: 'Not Applicable',
      ckycNo: '',
      dlclId: '',
      dpId: '',
      clId: '',
      nameOfBroker: '',
      otherNameOfBroker: '',
      applicant_type: '',
    },
  ],
  saveType: 'save and proceed',
};

const KYCDetails = ({
  applicant,
  index,
  values,
  brokers,
  ifTopUp,
}: {
  applicant: string;
  index: number;
  values: Values;
  brokers: Broker[];
  ifTopUp: boolean;
}): JSX.Element => {
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  return (
    <>
      <SubHeading>Additional KYC Details of {applicant} Applicant</SubHeading>
      <Grid item xs={12} sm={6}>
        <MFSelectField
          name={`applicants.${index}.occupationDetails`}
          label="Occupation Details *"
          items={occupationDetailsMasters.map((occupation) => ({
            key: occupation,
            value: occupation,
          }))}
          disabled={ifTopUp}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFSelectField
          name={`applicants.${index}.grossAnnualIncome`}
          label="Gross Annual Income"
          items={grossAnnualMasters.map((grossIncome) => ({
            key: grossIncome,
            value: grossIncome,
          }))}
          disabled={ifTopUp}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFSelectField
          name={`applicants.${index}.politicallyExposedPersonStatus`}
          label="Politically Exposed Person (PEP) Status *"
          items={PEPsMasters.map((pep) => ({ key: pep, value: pep }))}
          disabled={ifTopUp}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`applicants.${index}.ckycNo`}
          label="CKYC Number"
          placeholder={'Enter CKYC number'}
          disabled={[USER_ROLES.INVESTOR, USER_ROLES.POAAPPROVER].includes(role) || ifTopUp}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFSelectField
          name={`applicants.${index}.dlclId`}
          label={`Demat Account Details (for allotment of units) ${
            isFirstApplicant(values.applicants[index]?.applicant_type || '') ? '*' : ''
          }`}
          items={Object.keys(DLCLMasters).map((Id) => ({
            key: DLCLMasters[Id],
            value: Id,
          }))}
          disabled={ifTopUp}
        />
      </Grid>
      {getdpIdField(values.applicants[index].dlclId || '') && (
        <Grid item xs={12} sm={6}>
          <MFTextField
            name={`applicants.${index}.dpId`}
            label={`${isCDSL(values.applicants[index].dlclId || '') ? 'DPCL ID' : 'DP ID'} ${
              isFirstApplicant(values.applicants[index]?.applicant_type || '') ? '*' : ''
            }`}
            placeholder={`Enter ${
              isCDSL(values.applicants[index].dlclId || '') ? 'DPCL ID' : 'DP ID'
            }`}
            inputProps={{ style: { textTransform: 'uppercase' } }}
            disabled={ifTopUp}
          />
        </Grid>
      )}
      {getclIdField(values.applicants[index].dlclId || '') && (
        <Grid item xs={12} sm={6}>
          <MFTextField
            name={`applicants.${index}.clId`}
            label={`CL ID ${
              isFirstApplicant(values.applicants[index]?.applicant_type || '') ? '*' : ''
            }`}
            placeholder="Enter CL ID"
            disabled={ifTopUp}
          />
        </Grid>
      )}
      {getdpIdField(values.applicants[index].dlclId || '') && (
        <Grid item xs={12} sm={6}>
          <SearchableSelect
            name={`applicants.${index}.nameOfBroker`}
            label="Name of Broker/Depository Participant (DP)"
            items={sortListKeepOthersLast(brokers || [])?.map((broker: Broker) => ({
              key: broker.value,
              value: broker.key,
            }))}
            searchFieldPlaceholder={'Search Broker/Depository Participant'}
            disabled={ifTopUp}
          />
        </Grid>
      )}
      {getdpIdField(values.applicants[index].dlclId || '') &&
        otherNameOfBroker(values.applicants[index].nameOfBroker || '') && (
          <Grid item xs={12} sm={6}>
            <MFTextField
              name={`applicants.${index}.otherNameOfBroker`}
              label="Name of Broker *"
              placeholder="Enter Name of Broker"
              disabled={ifTopUp}
            />
          </Grid>
        )}
    </>
  );
};

export default function AdditionalKYCDetails(): JSX.Element {
  const [kycDetails, setkycDetails] = useState(initialValues);
  const { application } = useSelector((store: RootStateType) => store.application);
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [brokerList, setBrokerList] = useState<BrokerList>();
  const { BrokerList } = useMdmsBrokerListData();
  const { toggler } = useFeatureToggles();

  useEffect(() => {
    (async function () {
      try {
        setBrokerList(BrokerList as BrokerList);
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
  }, [BrokerList]);

  useEffect(() => {
    (async function () {
      try {
        const { applicants: exisitingApplicants = [] } = application || {};
        setkycDetails({
          ...kycDetails,
          applicants: exisitingApplicants.map((applicant) => {
            return {
              occupationDetails: applicant.occupationDetails || '',
              grossAnnualIncome: applicant.grossAnnualIncome || '',
              politicallyExposedPersonStatus: applicant.politicallyExposedPersonStatus
                ? applicant.politicallyExposedPersonStatus
                : [USER_ROLES.INVESTOR, USER_ROLES.POAAPPROVER].includes(role)
                ? ''
                : 'Not Applicable',
              ckycNo: applicant.ckycNo || '',
              dlclId: applicant.dlclId ? applicant.dlclId : '',
              dpId: applicant.dpId || '',
              clId: applicant.clId || '',
              nameOfBroker: applicant.nameOfBroker
                ? brokerList?.broker_list &&
                  nameOfBrokerExists(
                    brokerList?.broker_list as Broker[],
                    applicant.nameOfBroker || ''
                  )
                  ? applicant.nameOfBroker
                  : 'others'
                : '',
              otherNameOfBroker:
                applicant.nameOfBroker &&
                brokerList?.broker_list &&
                nameOfBrokerExists(
                  brokerList?.broker_list as Broker[],
                  applicant.nameOfBroker || ''
                )
                  ? ''
                  : applicant.nameOfBroker,
              applicant_type: applicant?.applicant_type || '',
            };
          }),
        });
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
  }, [application, brokerList]);

  const handleSubmit = async (values: Values) => {
    try {
      const { applicants, saveType } = values;
      const {
        applicants: exisitingApplicants = [],
        id,
        applicant1ReferenceId = '',
        currentStep,
        applicationNumber,
        topupApplicationNumber,
      } = application || {};
      const updatedApplicants = exisitingApplicants.map((applicant, index) => {
        const {
          otherNameOfBroker: othersNameOfBroker,
          // eslint-disable-next-line
          applicant_type,
          ...rest
        } = applicants[index];
        return {
          ...applicant,
          ...rest,
          clId: clearclIdField(applicants[index].dlclId || '') ? '' : applicants[index].clId,
          dpId: cleardpIdField(applicants[index].dlclId || '')
            ? ''
            : applicants[index]?.dpId?.toUpperCase(),
          nameOfBroker: cleardpIdField(applicants[index].dlclId || '')
            ? ''
            : otherNameOfBroker(applicants[index].nameOfBroker || '')
            ? othersNameOfBroker
            : applicants[index].nameOfBroker,
        };
      });
      const checkApplication = applicationComparison(application, {
        ...application,
        applicants: updatedApplicants,
        currentStep: !!currentStep && currentStep > 3 ? currentStep : Number(currentStep) + 1,
      });
      const isSaveLater = saveType !== 'save and proceed';
      if (id && !checkApplication) {
        setLoading(true);
        await dispatch(
          updateApplication({
            body: {
              ...application,
              applicants: updatedApplicants,
              status: statusRoleBased(role, application),
              currentStep: getStep(4, isSaveLater),
              ...((!SAVE_LATER_VALIDATION_CHECK as boolean) && { saveForLater: isSaveLater }),
            },
            applicationId: id,
            ...(isSaveLater && { toastMessage: '' }),
          })
        );
        !isSaveLater
          ? history.push('fatca', {
              id,
              applicant1ReferenceId,
              topUpApplication: application?.topupInitiated,
            })
          : history.push(
              saveForLater(role, id, applicant1ReferenceId, application?.topupInitiated),
              {
                topUpApplication: application?.topupInitiated,
              }
            );
      } else if (checkApplication) {
        if (isSaveLater) {
          enqueueSnackbar(
            `Application ${
              application?.topupInitiated ? topupApplicationNumber : applicationNumber
            } - ` + ' Saved successfully',
            {
              variant: 'success',
              autoHideDuration: 3000,
            }
          );
        }
        !isSaveLater
          ? history.push('fatca', {
              id,
              applicant1ReferenceId,
              topUpApplication: application?.topupInitiated,
            })
          : history.push(
              saveForLater(role, id, applicant1ReferenceId, application?.topupInitiated),
              {
                topUpApplication: application?.topupInitiated,
              }
            );
      }
    } catch (e) {
      setLoading(false);
      console.error((e as Error).message);
    }
  };

  return (
    <Formik
      initialValues={kycDetails}
      onSubmit={handleSubmit}
      validate={(values: Values) => {
        try {
          validateYupSchema(
            values,
            KYCDetailsSchema(
              brokerList?.broker_list as Broker[],
              application?.createdAt || '',
              toggler
            ),
            true,
            values
          );
        } catch (e) {
          return yupToFormErrors(e);
        }
      }}
      enableReinitialize={true}>
      {({ handleSubmit, values, setValues }) => (
        <Grid
          container
          rowSpacing={1}
          // columnSpacing={5}
          sx={{
            width: '100%',
            ml: 0,
            '.MuiGrid-item': { px: { xs: 0, sm: '30px' } },
          }}
          component="form"
          noValidate
          onSubmit={handleSubmit}>
          {values.applicants.map((_, index) => (
            <KYCDetails
              applicant={getApplicantName(index + 1)}
              key={index}
              index={index}
              values={values}
              brokers={brokerList?.broker_list as Broker[]}
              ifTopUp={application?.topupInitiated || false}
            />
          ))}
          <FieldValidationNote />
          <ProceedSaveLater
            saveLater={() => {
              setValues({
                ...values,
                saveType: 'save for later',
              });
            }}
            saveAndProceed={() => {
              setValues({
                ...values,
                saveType: 'save and proceed',
              });
            }}
            loader={loading}
            clickedButton={values.saveType}
          />
        </Grid>
      )}
    </Formik>
  );
}
