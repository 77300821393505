import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material';
import { MFSubmitButton, MFTextField } from '../../lib/formik';
import { Formik, useFormikContext } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import {
  Distributor,
  getDistributors,
  getDistributorsResponse,
  getRMsList,
  SubDistributor,
} from '../../redux-store/actions';
import React, { useEffect, useState } from 'react';
import { RootStateType } from '../../redux-store/reducers';
import { updateApplication } from '../../redux-store/actions/application';
import { useHistory } from 'react-router';
import {
  POA_AUTHORIZED_SIGNATORY_INDIVIDUAL_FLOW_VISIBLITY_ENABLE,
  POA_Authorized_signatory_can_Esign_Enable,
  USER_ROLES,
  subdistributorTypes,
} from '../../utils/constant';
import { InfoDialog, Notes, SubHeading } from './components';
import UseRadioGroup from '../../lib/formik/Radio';
import { distributorsValidationSchema } from '../../utils/schema';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  applicationComparison,
  checkIfApplicationIsIndividualPOA,
  checkValidationBasedOnDate,
  statusRoleBased,
  checkSupportLogin,
  checkSubDisDisable,
} from '../../utils/utilityFunctions';
import { SearchableSelect } from '../../lib/formik/searchSelectField';
import { ConfirmationDialog } from '../commonComponents';
import {
  ApplicationProps,
  POAAuthorisedSignatories,
  SubDistributorListType,
  SupportRmProp,
} from '../../redux-store/types/api-types';
import { POAAuthorisedSignatoriesFragment } from '../NonIndividualInvestor/Authorised';
import { showError } from '../../redux-store/actions/auth';
import { DistributorListType } from '../../redux-store/types/api-types';
import { getDistributorById, getDistributorRMbyId } from '../../redux-store/actions/userManagement';
import { getDirectApplicableFunds } from '../../redux-store/actions/funds';
import { FundProps } from '../../redux-store/types/funds';
import { RMType } from '../../redux-store/types/api-types';
import { useFeatureToggles } from '../../utils/useDataMdms';

type DistributorDetailsProps = {
  distributorName: string | null;
  distributorId: number | null;
  loginType: string;
  firstName: string;
  empCode: string;
  email: string;
  subdistributorId: number | null;
  subdistributorName: string | null;
  subdistributorEmail: string | null;
  supportDistributorEmail: string | null;
  supportDistributorPhone: string | null;
  supportSubdistributorId: string | null | number;
  filledBy: string | null;
  rm_id: number | null | undefined;
  distributorArnCode: string | null;
};

type POAApplication = {
  authorisedSignatories: POAAuthorisedSignatories[];
  thrashold: number;
};

function GridItem({ children }: { children: React.ReactNode }) {
  return (
    <Grid item xs={12} sm={6}>
      {children}
    </Grid>
  );
}

const RMDetail = ({
  isSupportRM,
  rmsList,
  values,
}: {
  isSupportRM: boolean;
  rmsList: SupportRmProp[];
  values: DistributorDetailsProps;
}) => {
  const rmsListSorting = [...rmsList].sort((a, b) => (a.name > b.name ? 1 : -1));
  return (
    <>
      <GridItem>
        <MFTextField name="firstName" label="RM Name" placeholder="Enter RM name" disabled={true} />
      </GridItem>
      <GridItem>
        <MFTextField
          name="email"
          label="RM Email ID"
          placeholder="Enter RM Email ID"
          disabled={true}
        />
      </GridItem>
      {isSupportRM && rmsList?.length > 0 && (
        <>
          <GridItem>
            <SearchableSelect
              name="rm_id"
              label="AMC's RM *"
              items={rmsListSorting.map((item: SupportRmProp) => ({
                key: item.name,
                value: item.id,
              }))}
              searchFieldPlaceholder={'Search RM'}
            />
          </GridItem>
          {values.rm_id && (
            <>
              <GridItem>
                <MFTextField
                  name="email"
                  label="AMC's RM Email ID"
                  placeholder="Enter AMC's RM Email ID"
                  value={
                    rmsList.find((item: SupportRmProp) => item.id === values.rm_id)?.user?.email
                  }
                  disabled={true}
                />
              </GridItem>
              <GridItem>
                <MFTextField
                  name="email"
                  label="AMC's RM Mobile Number"
                  placeholder="Enter AMC's RM Mobile Number"
                  value={
                    rmsList.find((item: SupportRmProp) => item.id === values.rm_id)?.user?.phone
                  }
                  disabled={true}
                />
              </GridItem>
            </>
          )}
        </>
      )}

      {/* <GridItem>
        <MFTextField
          name="empCode"
          label="Employee Code"
          placeholder="Enter Employee Code"
          disabled={true}
        />
      </GridItem> */}
    </>
  );
};

const DistributorDetail = ({
  distributors,
  values,
  selectedDistributor,
  setSelectedDistributor,
  selectSupport,
  setSelectSupport,
  application,
  authorisedSignatoriesPOA,
  setAuthorisedSignatoriesPOA,
}: {
  distributors: Distributor[];
  values: DistributorDetailsProps;
  selectedDistributor: SubDistributor[];
  setSelectedDistributor: (distributor: SubDistributor[]) => void;
  selectSupport: SupportRmProp[];
  setSelectSupport: (distributor: SupportRmProp[]) => void;
  application: ApplicationProps | null;
  authorisedSignatoriesPOA: POAApplication;
  setAuthorisedSignatoriesPOA: (e: POAApplication) => void;
}): JSX.Element => {
  const { role } = useSelector((store: RootStateType) => store.auth);
  const { setValues } = useFormikContext();
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const { auth } = useSelector((store: RootStateType) => store);
  const [openDialog, setDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const { toggler } = useFeatureToggles();
  const { poaValidationDate = '' } = toggler?.dateFeatureToggles || {};

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleDialogOpen = () => {
    setDialog(true);
  };
  const handleDialogClose = () => {
    setDialog(false);
  };

  return (
    <>
      {distributors.length > 0 ? (
        <>
          {[USER_ROLES.RM].includes(role) && !!values.distributorId && (
            <Grid item xs={12} sx={{ textAlign: 'right' }}>
              <Button
                variant="contained"
                sx={{
                  color: 'white',
                  fontWeight: 500,
                  fontSize: 14,
                  py: 0.5,
                  px: 3,
                }}
                onClick={handleDialogOpen}
                disabled={application?.topupInitiated || false}>
                clear
              </Button>
            </Grid>
          )}
          <GridItem>
            <SearchableSelect
              name="distributorId"
              label="Distributor Name *"
              items={distributors
                .sort((distributor1, distributor2) =>
                  (distributor1['name'] || '')
                    .toString()
                    .localeCompare((distributor2['name'] || '').toString())
                )
                .map((item) => ({
                  key: (item.name || 'NA') + ' - ' + (item.arnCode || 'NA'),
                  value: item.id,
                }))}
              onChange={({ target: { value } }) => {
                const distributorCode = distributors.find((item) => item.id === value);
                const { id = null, name, arnCode } = distributorCode || {};
                setValues({
                  ...values,
                  distributorName: name,
                  distributorArnCode: arnCode || 'NA',
                  distributorId: id,
                  subdistributorId: null,
                  subdistributorName: null,
                  subdistributorEmail: null,
                  supportDistributorEmail: '',
                  supportDistributorPhone: '',
                  supportSubdistributorId: null,
                });
                setSelectedDistributor(
                  distributors
                    ?.find((distributor) => distributor.id === value)
                    ?.subdistributors?.filter(
                      (ele) =>
                        subdistributorTypes[ele.subdistributorType || ''] ===
                        subdistributorTypes.direct
                    ) || []
                );
              }}
              disabled={
                [
                  USER_ROLES.DISTRIBUTOR,
                  USER_ROLES.INVESTOR,
                  USER_ROLES.POAAPPROVER,
                  USER_ROLES.SUBDISTRIBUTOR,
                  USER_ROLES.AMCAPPROVER,
                  USER_ROLES.RTAAPPROVER,
                  USER_ROLES.AMC_ADMIN,
                ].includes(role) || !!application?.topupInitiated
              }
              applyRenderValue={true}
              renderValuePosition={0}
              searchFieldPlaceholder={'Search Distributor'}
            />
            {loading && (
              <Box sx={{ display: 'flex' }}>
                <CircularProgress size={30} />
              </Box>
            )}
          </GridItem>
          <GridItem>
            <MFTextField
              name="distributorArnCode"
              label="Distributor ARN Code"
              placeholder="Enter Distributor ARN Code"
              disabled={true}
            />
          </GridItem>
          {/* <GridItem>
            <MFTextField
              name="distributorName"
              label="Distributor Name *"
              placeholder="Enter Distributor name"
              disabled={true}
            />
          </GridItem> */}
          {!!selectedDistributor?.length && (
            <>
              <GridItem>
                <SearchableSelect
                  name="subdistributorId"
                  label="Distributor's RM Name - Code *"
                  items={selectedDistributor
                    .sort((subDistributor1, subDistributor2) =>
                      (subDistributor1.user['firstName'] || '')
                        .toString()
                        .localeCompare((subDistributor2.user['firstName'] || '').toString())
                    )
                    .map((item) => ({
                      key: item.user.firstName + ' - ' + (item.distributors_RM_Code || 'NA'),
                      value: item.id,
                    }))}
                  onChange={async ({ target: { value } }) => {
                    try {
                      setLoading(true);
                      const getDistributorRmDetails = (await dispatch(
                        getDistributorRMbyId(Number(value))
                      )) as unknown as SubDistributorListType;
                      setSelectSupport(
                        (getDistributorRmDetails?.supportSubdistributorDetails
                          ?.supportSubdistributor as unknown as SupportRmProp[]) || []
                      );
                      const {
                        id = null,
                        user,
                        supportSubdistributorId = null,
                        supportSubdistributorDetails,
                      } = getDistributorRmDetails || {};
                      const { supportSubdistributor = [] } = supportSubdistributorDetails || {};
                      const { email, phone } = user || {};

                      const checkSupportPresent =
                        supportSubdistributorDetails.supportSubdistributorId;
                      setValues({
                        ...values,
                        subdistributorId: id,
                        subdistributorEmail: email || '',
                        subdistributorPhone: phone || '',
                        supportDistributorEmail: !checkSupportPresent
                          ? ''
                          : !checkSupportLogin(
                              Number(auth.id),
                              Number(application?.supportSubdistributor?.user.id)
                            )
                          ? supportSubdistributor[0]?.user?.email
                          : application?.supportSubdistributor.user.email || '',
                        supportDistributorPhone: !checkSupportPresent
                          ? ''
                          : !checkSupportLogin(
                              Number(auth.id),
                              Number(application?.supportSubdistributor?.user.id)
                            )
                          ? supportSubdistributor[0]?.user?.phone
                          : application?.supportSubdistributor.user.phone || '',
                        supportSubdistributorId: !checkSupportPresent
                          ? null
                          : !checkSupportLogin(
                              Number(auth.id),
                              Number(application?.supportSubdistributor?.user.id)
                            )
                          ? supportSubdistributorId
                          : application?.supportSubdistributorId,
                      });
                      setLoading(false);
                    } catch (e: unknown) {
                      setLoading(false);
                      console.error((e as Error).message);
                    }
                  }}
                  disabled={
                    checkSubDisDisable(
                      role,
                      auth.id || '',
                      application?.supportSubdistributor?.user?.id || ''
                    ) || !!application?.topupInitiated
                  }
                  searchFieldPlaceholder={"Search Distributor's RM"}
                />
              </GridItem>
              {/* <GridItem>
            <MFTextField
              name="subdistributorName"
              label="Distributor's RM Name *"
              placeholder="Enter Distributor name"
              disabled={true}
            />
          </GridItem> */}
              <GridItem>
                <MFTextField
                  name="subdistributorEmail"
                  label="Distributor's RM Email *"
                  placeholder="Enter Distributor email"
                  disabled={true}
                />
              </GridItem>

              {application?.supportSubdistributor?.user.id &&
                !checkSupportLogin(
                  Number(auth.id),
                  Number(application?.supportSubdistributor?.user.id)
                ) && (
                  <>
                    <GridItem>
                      <SearchableSelect
                        name="supportSubdistributorId"
                        label="CSE Name *"
                        items={selectSupport
                          ?.sort((support1, support2) =>
                            (support1.user['firstName'] || '')
                              ?.toString()
                              .localeCompare((support2.user['firstName'] || '').toString())
                          )
                          ?.map((item) => ({
                            key: item?.user?.firstName,
                            value: item?.id,
                          }))}
                        searchFieldPlaceholder={'Search CSE Name'}
                        disabled={true}
                      />
                    </GridItem>
                    <GridItem>
                      <MFTextField
                        name="supportDistributorEmail"
                        label="CSE Email *"
                        placeholder="Enter CSE Email"
                        disabled={true}
                      />
                    </GridItem>
                    <GridItem>
                      <MFTextField
                        name="supportDistributorPhone"
                        label="CSE Mobile Number"
                        placeholder="Enter CSE Mobile Number"
                        disabled={true}
                      />
                    </GridItem>
                  </>
                )}
            </>
          )}
          <GridItem>
            <MFTextField
              name="filledBy"
              label="Filled by"
              placeholder="Enter filled by"
              disabled={
                ![USER_ROLES.DISTRIBUTOR, USER_ROLES.RM, USER_ROLES.SUBDISTRIBUTOR].includes(
                  role
                ) || !!application?.topupInitiated
              }
            />
            <Typography
              component="span"
              sx={{ position: 'relative', left: 64, bottom: { xs: 85, md: 91 } }}>
              <InfoOutlinedIcon color="info" fontSize="small" onClick={handleOpen} />
            </Typography>
          </GridItem>
          <InfoDialog
            onClose={handleClose}
            open={open}
            content={'Please add the Name of the Subdistributor RM.'}
          />

          {application &&
            authorisedSignatoriesPOA?.authorisedSignatories?.length >= 0 &&
            checkValidationBasedOnDate(application.createdAt || '', poaValidationDate) &&
            checkIfApplicationIsIndividualPOA(application) &&
            POA_AUTHORIZED_SIGNATORY_INDIVIDUAL_FLOW_VISIBLITY_ENABLE && (
              <>
                <SubHeading>Authorised Signatories</SubHeading>
                {/* <Typography
                  component="h3"
                  sx={{
                    fontFamily: 'Poppins, sans-serif',
                    fontSize: 25,
                    fontWeight: 500,
                    mb: { xs: 2, md: 3.5 },
                    color: 'secondary.main',
                  }}>
                  Authorised Signatories
                </Typography> */}
                <POAAuthorisedSignatoriesFragment
                  onChange={(e) => setAuthorisedSignatoriesPOA(e)}
                  values={authorisedSignatoriesPOA}
                  application={application}
                />
              </>
            )}
        </>
      ) : (
        <Grid
          xs={12}
          sm={12}
          sx={{
            fontSize: 18,
            fontWeight: 500,
            color: 'rgb(97, 209, 214)',
            display: 'flex',
            alignItems: 'center',
            textAlign: 'center',
            justifyContent: 'center',
            mt: { lg: 9, xs: 9 },
            mb: 2.9,
            ml: { xs: 3 },
          }}>
          No distributor mapped under this RM
        </Grid>
      )}
      <ConfirmationDialog
        message={`Are you sure you want to clear RM/Distributor details ?`}
        open={openDialog}
        setOpen={handleDialogClose}
        onSave={() => {
          handleDialogClose();
          setValues({
            ...values,
            distributorName: '',
            distributorArnCode: null,
            distributorId: null,
            subdistributorId: null,
            subdistributorName: '',
            subdistributorEmail: '',
            supportSubdistributorId: null,
            supportDistributorEmail: '',
            supportDistributorPhone: '',
          });
          setSelectedDistributor([]);
        }}
        onCancel={handleDialogClose}
      />
    </>
  );
};

export default function DistributorDetails(): JSX.Element {
  const dispatch = useDispatch();
  const history = useHistory();
  const [distributors, setDistributors] = useState<Distributor[]>([]);
  const { application } = useSelector((store: RootStateType) => store.application);
  const { role } = useSelector((store: RootStateType) => store.auth);
  const [selectedDistributor, setSelectedDistributor] = useState<SubDistributor[]>([]);
  const [selectSupport, setSelectSupport] = useState<SupportRmProp[]>([]);
  const { auth } = useSelector((store: RootStateType) => store);
  const { toggler } = useFeatureToggles();
  const { poaValidationDate = '' } = toggler?.dateFeatureToggles || {};
  const isSupportRM = auth.id === application?.supportRm?.userId;

  const {
    distributor,
    rm,
    rm_id,
    subdistributorId = null,
    subDistributor,
    filledBy = '',
    supportSubdistributorId = null,
    supportSubdistributor,
    supportRm,
  } = application || {};

  const { name, id, arnCode } = distributor || {
    arnCode: '',
    name: '',
    id: null,
  };

  const {
    empCode,
    user: { email: RMEmail = '', firstName: RMFirstName = '' },
  } = (isSupportRM ? supportRm : rm) || { empCode: '', user: { email: '' } };

  const {
    user: { firstName: subdistributorName, email: subdistributorEmail },
  } = subDistributor || { user: { firstName: '', email: '' } };

  const { user } = supportSubdistributor || {};

  const initialValues: DistributorDetailsProps = {
    distributorId: id,
    distributorName: name,
    loginType: [USER_ROLES.DISTRIBUTOR, USER_ROLES.SUBDISTRIBUTOR].includes(role)
      ? USER_ROLES.DISTRIBUTOR
      : USER_ROLES.RM,
    empCode,
    email: RMEmail,
    firstName: RMFirstName,
    subdistributorId: subdistributorId === null ? subdistributorId : Number(subdistributorId),
    subdistributorName,
    subdistributorEmail,
    supportSubdistributorId:
      supportSubdistributorId === null ? supportSubdistributorId : Number(supportSubdistributorId),
    supportDistributorEmail: user?.email || '',
    supportDistributorPhone: user?.phone || '',
    filledBy,
    rm_id,
    distributorArnCode: id ? arnCode || 'NA' : null,
  };
  const [authorisedSignatoriesPOA, setAuthorisedSignatoriesPOA] = useState<POAApplication>({
    authorisedSignatories: application?.poaauthorisedsignatories || [],
    thrashold: application?.requiredAuthorisedSignatory || 0,
  });

  const [rmsList, setRmList] = useState<RMType[]>([]);

  useEffect(() => {
    let isComponentActive = true;
    (async function () {
      try {
        if (Object.keys(application || {}).length && role === USER_ROLES.RM) {
          const res = (await dispatch(
            getDistributors({ isActive: true })
          )) as unknown as getDistributorsResponse;
          const { distributors = [] } = res || {};
          const updatedDistributor =
            application?.distributor_id && !application.distributor.isActive
              ? [
                  ...distributors,
                  {
                    ...application.distributor,
                    subdistributors: [application.subDistributor].filter((ele) => ele),
                  } as Distributor,
                ]
              : distributors;

          let updateRMList: RMType[] = [];
          if (isSupportRM) {
            const rms = (await dispatch(
              getRMsList({ roleType: 'amc_rm', isActive: true })
            )) as unknown as RMType[];
            updateRMList =
              application?.rm_id && !application.rm.isActive ? [...rms, application.rm] : rms;
          }

          if (isComponentActive) {
            const updateSubdistributor = (
              application?.distributor_id &&
              application?.distributor.isActive &&
              application?.subdistributorId &&
              !application.subDistributor?.isActive
                ? [
                    ...(updatedDistributor.find((distributor) => distributor.id === id)
                      ?.subdistributors || []),
                    { ...application.subDistributor },
                  ]
                : updatedDistributor.find((distributor) => distributor.id === id)
                    ?.subdistributors || []
            )
              .filter((ele) => ele)
              .filter(
                (ele) =>
                  subdistributorTypes[ele.subdistributorType || ''] === subdistributorTypes.direct
              );
            setSelectSupport([application?.supportSubdistributor as SupportRmProp]);
            setSelectedDistributor(updateSubdistributor as unknown as SubDistributor[]);
            setDistributors(updatedDistributor as unknown as Distributor[]);
            setRmList(updateRMList);
          }
        } else if (
          application &&
          supportSubdistributor &&
          checkSupportLogin(Number(auth.id), supportSubdistributor?.user.id)
        ) {
          const { distributor } = application || {};
          setDistributors(distributor ? [distributor as Distributor] : []);
          setSelectSupport([application?.supportSubdistributor as SupportRmProp]);
          const getDistributorRmDetails = (await dispatch(
            getDistributorRMbyId(Number(application?.supportSubdistributorId))
          )) as unknown as SubDistributorListType;
          setSelectedDistributor(
            getDistributorRmDetails?.directSubdistributorsDetails
              .directSubdistributors as unknown as SubDistributor[]
          );
        } else {
          const { distributor, subDistributor } = application || {};
          setDistributors(distributor ? [distributor as Distributor] : []);
          setSelectSupport([application?.supportSubdistributor as SupportRmProp]);
          setSelectedDistributor(
            subDistributor
              ? [{ ...subDistributor, id: Number(subDistributor.id) } as SubDistributor]
              : []
          );
        }
        setAuthorisedSignatoriesPOA({
          authorisedSignatories: application?.poaauthorisedsignatories || [],
          thrashold: application?.requiredAuthorisedSignatory || 0,
        });
      } catch (e: unknown) {
        console.error((e as Error).message);
      }
    })();
    return () => {
      isComponentActive = false;
    };
  }, [application?.id]);

  // useEffect(() => {
  //   setAuthorisedSignatoriesPOA({
  //     authorisedSignatories: application?.poaauthorisedsignatories || [],
  //     thrashold: application?.requiredAuthorisedSignatory || 0,
  //   });
  // }, [application]);

  const onSubmit = async (values: DistributorDetailsProps) => {
    try {
      const { distributorId, subdistributorId, filledBy, supportSubdistributorId = null } = values;
      const { id, applicant1ReferenceId, currentStep = null, isTopup } = application || {};
      if (
        application &&
        checkValidationBasedOnDate(application.createdAt || '', poaValidationDate) &&
        checkIfApplicationIsIndividualPOA(application) &&
        POA_Authorized_signatory_can_Esign_Enable &&
        POA_AUTHORIZED_SIGNATORY_INDIVIDUAL_FLOW_VISIBLITY_ENABLE
      ) {
        const getCanEsignCount = authorisedSignatoriesPOA.authorisedSignatories?.filter(
          (item) => item.canEsign
        );
        if (!authorisedSignatoriesPOA.authorisedSignatories?.length) {
          throw 'Please add Authorised Signatories';
        }
        if (!getCanEsignCount.length) {
          throw 'Please Select a member for E-Sign';
        }
        if (getCanEsignCount.length < authorisedSignatoriesPOA.thrashold) {
          throw 'Authorised Signatories that can esign can not be less than the Total No. of required Authorised Signatories';
        }
      }

      const Poa_can_esign =
        POA_Authorized_signatory_can_Esign_Enable &&
        POA_AUTHORIZED_SIGNATORY_INDIVIDUAL_FLOW_VISIBLITY_ENABLE
          ? {
              poaauthorisedsignatories:
                application && checkIfApplicationIsIndividualPOA(application)
                  ? authorisedSignatoriesPOA.authorisedSignatories
                  : application?.poaauthorisedsignatories,
              requiredAuthorisedSignatory:
                application && checkIfApplicationIsIndividualPOA(application)
                  ? authorisedSignatoriesPOA.thrashold
                  : application?.requiredAuthorisedSignatory,
            }
          : {};

      const updatedApplication = {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        ...application!,
        ...Poa_can_esign,
        distributor_id: distributorId,
        subdistributorId: subdistributorId ? subdistributorId.toString() : subdistributorId,
        supportSubdistributorId: supportSubdistributorId
          ? supportSubdistributorId.toString()
          : supportSubdistributorId,
        filledBy: filledBy,
      };

      const checkApplication = applicationComparison(application, {
        ...updatedApplication,
        currentStep: currentStep === null ? 1 : currentStep,
        rm_id: values.rm_id,
      });
      let restSchemePlan = {};
      const isDistributorExists =
        !distributorId &&
        application?.distributor_id &&
        (application?.schemeId || application?.planId);
      if (distributorId || isDistributorExists) {
        // eslint-disable-next-line
        let getPlanId: any[] = [];
        if (Number(distributorId) !== Number(application?.distributor_id) && distributorId) {
          getPlanId = (
            (await dispatch(getDistributorById(distributorId))) as unknown as DistributorListType
          )?.planDetails.planIds;
        } else if (isDistributorExists) {
          (
            (await dispatch(getDirectApplicableFunds({ isActive: true }))) as unknown as FundProps[]
          )?.map((fund) => {
            getPlanId.push(
              ...fund.plans?.filter((fund) => fund.isApplicableForDirect)?.map((plan) => plan.id)
            );
            return fund;
          });
        }
        if (
          application?.planId &&
          !getPlanId.map((ele) => ele?.toString()).includes(application?.planId?.toString())
        ) {
          //reset whatEver Fields used in investmentPayment details section
          restSchemePlan = {
            schemeId: null,
            planId: null,
            commitmentAmount: 0,
            setupFeePercentage: 0,
            managementFeesRate: 0,
            contributionChequeAmount: 0,
            taxRate: 0,
            drawDownNumber: 0,
            setupFeeAmount: null,
            setupFeeGST: null,
            totalSetupFee: null,
            setupFeeTDS: null,
            netSetupFeeLessTDS: null,
            totalAmountPayable: null,
          };
        }
      }
      if (id && !checkApplication) {
        await dispatch(
          updateApplication({
            body: {
              ...updatedApplication,
              status: statusRoleBased(role, application),
              ...restSchemePlan,
              currentStep: 1,
              //currentStep === null ? 1 : currentStep
              rm_id: values.rm_id,
            },
            applicationId: id,
          })
        );
        history.push(isTopup ? 'bank-details' : 'contributor-details', {
          id,
          applicant1ReferenceId,
          topUpApplication: application?.topupInitiated,
        });
      } else if (checkApplication) {
        history.push(isTopup ? 'bank-details' : 'contributor-details', {
          id,
          applicant1ReferenceId,
          topUpApplication: application?.topupInitiated,
        });
      }
    } catch (e) {
      typeof e === 'string' && dispatch(showError(e));
      console.error((e as Error).message);
    }
  };
  console.log(authorisedSignatoriesPOA);
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={distributorsValidationSchema(
        selectedDistributor,
        distributors,
        isSupportRM
      )}
      onSubmit={onSubmit}
      enableReinitialize={true}>
      {({ handleSubmit, values }) => (
        <Box component="form" noValidate onSubmit={handleSubmit}>
          <Notes
            displayContent={
              'This section specifies whether the application is from an RM/Distributor’s RM.'
            }
          />
          <Grid container rowSpacing={2} columnSpacing={5}>
            {/* Only maker can have  the edit access for fields */}
            {([USER_ROLES.RM, USER_ROLES.AMC_ADMIN].includes(role) ||
              (application?.hasPOA &&
                ![USER_ROLES.DISTRIBUTOR, USER_ROLES.SUBDISTRIBUTOR].includes(role))) && (
              <Grid item xs={12}>
                <UseRadioGroup
                  formLabel=""
                  name={`loginType`}
                  items={[
                    { label: 'Direct', value: USER_ROLES.RM },
                    { label: 'Distributor', value: USER_ROLES.DISTRIBUTOR },
                  ]}
                />
              </Grid>
            )}
            {
              // ![USER_ROLES.DISTRIBUTOR, USER_ROLES.SUBDISTRIBUTOR, USER_ROLES.POAAPPROVER].includes(
              //   role
              // ) &&
              values.loginType === USER_ROLES.RM ? (
                <RMDetail
                  isSupportRM={isSupportRM}
                  rmsList={rmsList as SupportRmProp[]}
                  values={values}
                />
              ) : (
                <DistributorDetail
                  distributors={distributors}
                  values={values}
                  selectedDistributor={selectedDistributor}
                  selectSupport={selectSupport}
                  setSelectSupport={setSelectSupport}
                  setSelectedDistributor={(subdistributors) =>
                    setSelectedDistributor(subdistributors)
                  }
                  application={application}
                  authorisedSignatoriesPOA={authorisedSignatoriesPOA}
                  setAuthorisedSignatoriesPOA={(e) => setAuthorisedSignatoriesPOA(e)}
                />
              )
            }
          </Grid>

          <Box sx={{ width: '100%', maxWidth: '350px', mx: 'auto', mt: 5 }}>
            <MFSubmitButton label="Next" />
          </Box>
        </Box>
      )}
    </Formik>
  );
}
